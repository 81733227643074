import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { UserAction, UserContext } from './providers/UserProvider';
import { Button, Container, ContainerFlex, TextLocalized } from './styled/Styled';

const Header = () => {
    let history = useHistory();
    let { dispatch } = useContext(UserContext);

    return (
        <ContainerFlex width="12" color="color-primary" fontColor="color-white" align="center" justify="space-between">
            <Container fontWeight="600" px="16" children="Carniceros Dashboard" />
            <ContainerFlex align="center">
                <Button onClick={() => history.push("/")} mh="4" px="16" textAlign="center" children={<TextLocalized children="msgDocuments" />} />
                <Button onClick={() => history.push("/announces")} mh="4" px="16" textAlign="center" children={<TextLocalized children="msgAnnouncementsTable" />} />
                <Button onClick={() => history.push("/allergens")} mh="4" px="16" textAlign="center" children={<TextLocalized children="msgAllergens" />} />
                <Button onClick={() => dispatch({ action: UserAction.logout })} mh="4" ph="16" pv="4" color="color-red-dark" fontColor="color-white" textAlign="center" children={<TextLocalized children="actionExit" />} />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Header;