import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Card, ContainerFlex, CustomInput } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { verifyEmailFormat } from '../components/util/UserUtil';
import back_txt from '../img/background_txt.png';

const LoginView = () => {
    const [formData, setFormData] = useState({ username: "", password: "" })
    const { dictionary } = useContext(LanguageContext)
    const { dispatch } = useContext(UserContext);

    let history = useHistory();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.username !== "" && formData.password !== "") {
            if (verifyEmailFormat(formData.username)) {
                //Send request
                HttpConnection(HttpOperations.login,
                    result => {
                        //If user is admin
                        if (result.usuario.type.id === 0) {
                            //Add clear password
                            result.usuario.password = formData.password;
                            history.replace("./");
                            dispatch({ action: UserAction.login, data: result })
                        } else {
                            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorUserIncorrect" } });
                        }
                    },
                    error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: error === 423 ? "errorUserRecjected" : "errorUserIncorrect" } }),
                    HttpMethod.post, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return (
        <ContainerFlex width="12" minheight="100vh" align="center" justify="center" color="color-primary">
            <ContainerFlex width="12" height="100vh" px="16" overflowY="auto" align="center" justify="center" image={back_txt}>
                <Card width="12" lg="4" color="color-white" px="16">
                    <h1 style={{ color: "var(--color-primary)" }}>Gremio Dashboard</h1>
                    <CustomInput onChange={onChange} titleph="16" title={"msgEmail"} placeholder={dictionary["msgEmail"]} type="email" name="username" value={formData.username} />
                    <CustomInput onChange={onChange} inputmv="8" titleph="16" title={"msgPassword"} placeholder={dictionary["msgPassword"]} type="password" name="password" value={formData.password} />

                    <ButtonFlat mt="48" action={sendRequest} label="actionLogin" fontColor="color-white" />
                </Card>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default LoginView;