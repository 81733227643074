const es = {
    "actionLogin": "INGRESAR A MI CUENTA",
    "actionContinue": "CONTINUAR",
    "actionConfirmCon": "CONFIRMAR Y CONTINUAR",
    "actionUpdate": "ACTUALIZAR",
    "actionEdit": "EDITAR",
    "actionDelete": "ELIMINAR",
    "actionBack": "VOLVER",
    "actionSave": "GUARDAR",
    "actionYes": "Sí",
    "actionNo": "No",
    "actionAdd": "AGREGAR",
    "actionCancel": "CANCELAR",
    "actionAccept": "ACEPTAR",
    "actionSelect": "SELECCIONAR",

    "actionAddAttached": "AÑADIR ADJUNTO",
    "actionAddClient": "ALTA NUEVO CLIENTE",
    "actionEditDetails": "EDITAR DETALLES",
    "actionAddPartner": "ALTA NUEVO PROVEEDOR",
    "actionShowEdit": "VER/EDITAR",
    "actionAddEmployee": "ALTA NUEVO TRABAJADOR",
    "actionAddTraining": "AÑADIR FORMACIÓN",
    "actionShowAll": "VER TODOS",
    "actionCreateProduct": "CREAR NUEVO PRODUCTO",
    "actionAddAdditive": "ALTA ADITIVO",
    "actionAddRawMaterial": "ALTA MATERIA PRIMA",
    "actionAddGuts": "ALTA TRIPAS",
    "actionCreateTicket": "CREAR ETIQUETA",
    "actionCreate": "Crear",
    "actionAddInspection": "AGREGAR INSPECCIÓN",
    "actionShowAttached": "VER ADJUNTO",
    "actionNew": "NUEVO",
    "actionExport": "EXPORTAR",
    "actionShow": "VER",
    "actionExit": "Salir",

    "msgEmail": "Email",
    "msgPassword": "Contraseña",
    "msgForget": "¿Has olvidado tu contraseña?",

    /* HOME */
    "msgAnnouncementsTable": "Tablón de anuncios",
    "msgDocuments": "Documentación",
    "msgAllergens": "Alérgenos",

    /* DOCUMENTS */
    "msgDocumentTitle": "Crea/edita documentos en la plataforma",
    "msgDocumentName": "Nombre del documento",
    "msgSelectFile": "Selecciona la imagen asociada",
    "msgDocumentList": "Listado de documentos",

    /* ANNOUNCES */
    "msgAnnounceTitle": "Crea/edita noticias en la plataforma",
    "msgTitle": "Título",
    "msgDescription": "Descripción",
    "msgAnnounceList": "Listado de noticias",
    "msgCompleteHere": "Completa aquí",

    /* ALLERGENS */
    "msgAllergensTitle": "Crea/edita alérgenos en la plataforma",
    "msgAllergenName": "Nombre del alérgeno",
    "msgAllergenstList": "Listado de alérgenos",

    /* MODAL */
    "msgAreSure": "¿Estás seguro?",

    "msgSuccess": "Datos actualizados correctamente",
    "msgDeleteSuccess": "Datos eliminados correctamente",

    "errorCode": "El código introducido no es correcto",
    "errorValidate": "Error al enviar usuario",
    "errorOblData": "Faltan datos obligatorios",
    "errorEmailFormat": "Formato de email incorrecto",
    "errorDNIRegister": "Ya existe una cuenta con este DNI/CIF",
    "errorUserRecjected": "Su usuario ha sido rechazado, contacte con la asociación",
    "errorUserIncorrect": "Email/contraseña incorrectos",
    "errorSend": "Error al enviar",
}

export default es;