import './css/normalize.css';
import './css/bootstrap-grid.css';
import './css/App.css';

import { LanguageProvider } from './components/providers/LanguageProvider';
import { UserContext, UserProvider } from './components/providers/UserProvider';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LoginView from './views/LoginView';
import DocumentsView from './views/DocumentsView';
import AnnouncesView from './views/AnnouncesView';
import AllergensView from './views/AllergensView';
import { Fragment, useContext } from 'react';
import { NotificationView } from './components/util/NotificationUtil';
import ModalView from './components/util/ModalUtil';
import { Container } from './components/styled/Styled';
import Header from './components/Header';

const SwitchView = () => {
  const { state } = useContext(UserContext);

  return <Fragment>
    <Container width="12" minheight="100vh" color="color-white">
      <Router basename="/">
        <Switch>
          {
            state.login ?
              <Fragment>
                <Header />
                <Container width="12" height="calc(100vh - 54px)" overflowY="auto" color="color-white" >
                  <Route exact={true} path="/" component={DocumentsView} />
                  <Route exact={true} path="/announces" component={AnnouncesView} />
                  <Route exact={true} path="/allergens" component={AllergensView} />
                </Container>
              </Fragment>
              :
              <Fragment>
                <Route exact={true} path="/" component={LoginView} />
                <Redirect to="/" />
              </Fragment>
          }
        </Switch>
      </Router>
      {state.notification.show ? <NotificationView /> : null}
    </Container>
    {state.modal.show ? <ModalView children={state.modal.body} /> : null}
  </Fragment>
}

const App = () => <LanguageProvider children={<UserProvider children={<SwitchView />} />} />


export default App;
