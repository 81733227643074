import React, { useContext, useEffect, useState } from 'react';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { ModalViewDefault } from '../components/util/ModalUtil';

const AllergenForm = ({ item, refreshItems }) => {
    const [formData, setFormData] = useState(item);

    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.descripcion) {
            HttpConnection(formData.id ? `${HttpOperations.alergenos}/${formData.id}` : HttpOperations.alergenos,
                result => {
                    //Refresh data
                    refreshItems();
                    //Hide popup
                    if (formData.id) dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
                    else setFormData({ descripcion: "" })
                    //Notify user
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
                },
                error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
                formData.id ? HttpMethod.put : HttpMethod.post, formData);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return <Card onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container fontWeight="bold" mx="16" fontSize="22px" children={<TextLocalized children="msgAllergensTitle" />} />
        <Container ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgAllergenName"} placeholder={dictionary["msgAllergenName"]} type="text" name="descripcion" value={formData.descripcion} />} />
        <ButtonFlat mx="16" width="auto" action={sendRequest} label="actionSave" fontColor="color-white" />
    </Card>
}

const AllergenItem = ({ item, editItem, deleteItem }) => {
    return <ContainerFlex width="12" px="16" align="center">
        <Container width="wrap" fontWeight="bold" children={item.descripcion} />
        <Button onClick={() => editItem(item)} px="12" mr="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionEdit" />} />
        <Button onClick={() => deleteItem(item)} px="12" ml="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionDelete" />} />
    </ContainerFlex>
}

const AllergensView = () => {
    const [items, setItems] = useState([]);
    const { dispatch } = useContext(UserContext);

    useEffect(() => getItems(), [])

    const getItems = () => HttpConnection(HttpOperations.alergenos,
        result => setItems(result), error => console.log(error), HttpMethod.get);

    const editIitem = (item) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <AllergenForm refreshItems={getItems} item={item} /> } });
    }

    const deleteItem = (item) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.alergenos}/${item.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "msgDeleteSuccess" } });
                getItems();
            },
            error => console.log(error), HttpMethod.delete)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    return (
        <ContainerFlex width="12" align="center" justify="center" direction="column" px="16">
            <AllergenForm item={{ descripcion: "" }} refreshItems={getItems} />
            <Card width="12" lg="6" mv="8" px="16" color="color-gray-card">
                <Container fontWeight="bold" mv="16" fontSize="22px" children={<TextLocalized children="msgAllergenstList" />} />
                {items.map((item, index) => <AllergenItem key={index} item={item} editItem={editIitem} deleteItem={deleteItem} />)}
            </Card>
        </ContainerFlex>
    );
};

export default AllergensView;