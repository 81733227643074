import { format } from 'date-fns';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpFormData, HttpImage, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { ModalViewDefault } from '../components/util/ModalUtil';

const DocumentForm = ({ item, refreshItems }) => {
    const [formData, setFormData] = useState(item);
    const [attach, setAttach] = useState();

    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    const inputRef = useRef();

    const sendRequest = () => {
        if (formData.titulo !== "" && attach) {
            HttpFormData(`${HttpOperations.documentos}/form`,
                result => {
                    //Set default values
                    setFormData({ titulo: "" });
                    setAttach(null);
                    //Refresh data
                    refreshItems();
                    //Notify user
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
                },
                error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
                HttpMethod.post, formData, [attach]);
        } else if (formData.id && formData.titulo) {
            HttpConnection(`${HttpOperations.documentos}/${formData.id}`,
                result => {
                    //Refresh data
                    refreshItems();
                    //Notify user
                    dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
                },
                error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
                HttpMethod.put, formData);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    const handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var image = new Image();
            image.src = URL.createObjectURL(file);

            image.onload = () => {
                URL.revokeObjectURL(image.src);
                setAttach({ file: file, url: reader.result });
            };
        }

        if (file !== null)
            reader.readAsDataURL(file)
    }

    return <Card onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container fontWeight="bold" mx="16" fontSize="22px" children={<TextLocalized children="msgDocumentTitle" />} />
        <Container ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgDocumentName"} placeholder={dictionary["msgDocumentName"]} type="title" name="titulo" value={formData.titulo} />} />
        {
            formData.archivo ? null :
                <ContainerFlex width="12" ph="16" align="center">
                    <Container pv="16" children={<TextLocalized children="msgSelectFile" />} />
                    {
                        attach
                            ? <Button onClick={() => setAttach(null)} mh="16" ph="16" pv="8" justify="center" align="center" color="color-black" fontColor="color-white" letterSpacing="1px" children={<TextLocalized children="actionDelete" />} />
                            : <Button onClick={() => inputRef.current.click()} mh="16" ph="16" pv="8" justify="center" align="center" color="color-white" fontColor="color-black" letterSpacing="1px" children={<TextLocalized children="actionSelect" />} />
                    }
                    <input onChange={handleImageChange} ref={inputRef} hidden type="file" />
                </ContainerFlex>
        }

        <ButtonFlat mx="16" width="auto" action={sendRequest} label="actionSave" fontColor="color-white" />
    </Card>
}

const DocumentItem = ({ item, showItem, editItem, deleteItem }) => {
    return <ContainerFlex width="12" px="16" align="center">
        <Container width="wrap">
            <Container width="12" fontWeight="bold" children={item.titulo} />
            <Container width="12" fontSize="12px" children={format(item.fechaInicio, "dd/MM/yyyy")} />
        </Container>

        <Button onClick={() => showItem(item)} px="12" mr="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionShow" />} />
        <Button onClick={() => editItem(item)} px="12" mr="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionEdit" />} />
        <Button onClick={() => deleteItem(item)} px="12" ml="4" color="color-white" fontWeight="500" fontSize="12px" fontColor="color-black" letterSpacing="1px" radius="12px" children={<TextLocalized children="actionDelete" />} />
    </ContainerFlex>
}

const DocumentsView = () => {
    const [items, setItems] = useState([]);
    const { dispatch } = useContext(UserContext);

    useEffect(() => getItems(), [])

    const getItems = () => HttpConnection(HttpOperations.documentos,
        result => setItems(result), error => console.log(error), HttpMethod.get);

    const showItem = (item) => {
        HttpImage(`${HttpOperations.archivo}/${item.archivo.id}/file`,
            result => dispatch({ action: UserAction.showModal, data: { show: true, body: <img alt="document" height="95%" src={result} /> } }),
            error => console.log(error));
    }

    const editIitem = (item) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <DocumentForm refreshItems={getItems} item={item} /> } });
    }

    const deleteItem = (item) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.documentos}/${item.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "msgDeleteSuccess" } });
                getItems();
            },
            error => console.log(error), HttpMethod.delete)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    return (
        <ContainerFlex width="12" align="center" justify="center" direction="column" px="16">
            <DocumentForm item={{ titulo: "" }} refreshItems={getItems} />
            <Card width="12" lg="6" mv="8" px="16" color="color-gray-card">
                <Container fontWeight="bold" mv="16" fontSize="22px" children={<TextLocalized children="msgDocumentList" />} />
                {items.map((item, index) => <DocumentItem key={index} item={item} showItem={showItem} editItem={editIitem} deleteItem={deleteItem} />)}
            </Card>
        </ContainerFlex>
    );
};

export default DocumentsView;